/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

export default function BurgerMenu() {
  const router = useRouter();

  const [display, setDisplay] = useState(false);
  useEffect(() => {
    setDisplay(false);
  }, [router.pathname]);

  return (
    <div className=''>
      <div className='w-full max-w-[1400px] p-5 backdrop-blur-sm flex-row-reverse bg-[#AAE2FF] bg-opacity-70 h-[120px] flex lg:hidden justify-between items-center z-[1000]  fixed ] '>
        <div className='__burger_menu'>
          <input
            className='__checkbox'
            type='checkbox'
            checked={display}
            onChange={(e) => {
              setDisplay(e.target.checked);
            }}
          />
          <div className='__burger_fry __fry_one'></div>
          <div className='__burger_fry __fry_two'></div>
          <div className='__burger_fry __fry_three'></div>
        </div>
        <div className='w-20  h-14  mr-5 sm:w-36 sm:h-24  relative'>
          <Image
            src={'/home-page/dreamywhales-logo.png'}
            alt={'neversea-logo'}
            layout='fill'
            className='invert'
          />
        </div>

        <div className='w-fit-content flex gap-2 '>
          <div
            className=' w-7 h-7 p-[0.5] rounded-md items-center justify-center bg-[#FF8200]
         '
          >
            <a
              target='_blank'
              href='https://discord.gg/dreamywhale'
              rel='noreferrer'
            >
              <img
                className=' '
                alt='neversea-discord'
                src='/home-page/universe-section/discord.svg'
              />
            </a>
          </div>
          <div
            className=' w-7 h-7 p-1 rounded-md items-center justify-center bg-[#FF8200]
         '
          >
            <a
              target='_blank'
              href='https://twitter.com/thedreamywhales'
              rel='noreferrer'
            >
              <img
                alt='neversea-twitter'
                className=' '
                src='/home-page/universe-section/twitter.svg'
              />
            </a>
          </div>
        </div>
      </div>

      <div className={`mobile_nav ${display ? 'mobile_nav_display' : ''}`}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div className='blur_background text-black'></div>
          {display && (
            <div className='mobile_nav_links_container text-black'>
              <a href={'#mission'} onClick={() => setDisplay(false)}>
                <h2 className=' flex justify-end font-bold mb-5'>Mission</h2>
              </a>
              <a href={'#ecosystem'} onClick={() => setDisplay(false)}>
                <h2 className=' flex justify-end font-bold mb-5'>Vision</h2>
              </a>
              <a href={'#universe'} onClick={() => setDisplay(false)}>
                <h2 className=' flex justify-end font-bold mb-5'>Lore</h2>
              </a>
              <a href={'#team'} onClick={() => setDisplay(false)}>
                <h2 className=' flex justify-end font-bold mb-5'>Team</h2>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
