// import Circle from './components/circle';
import { content } from './content';

// import autoAnimate from '@formkit/auto-animate';
// import { useEffect, useRef, useState } from 'react';

export default function Roadmap() {
  return (
    <div className='flex flex-col items-center px-4 md:px-6'>
      <h2 className='font-underwater titleStrokeMobile md:titleStroke text-[60px] text-center mb-10 text-secondary w-[98%]'>
        Utilites Explained
      </h2>
      <div className='grid-cols-1 sm:grid-cols-2 self-center lg:grid-cols-3 w-full gap-14 grid xl:w-full'>
        {content.map((item: any, index: number) => {
          return <Card key={index} content={item} />;
        })}
      </div>
    </div>
  );
}

function Card({ content }: any) {
  return (
    <div className='w-full p-6 flex h-full  border-black rounded-md border-4 flex-col'>
      <h2 className='text-secondary descriptionStroke  text-center mb-12 font-underwater text-4xl'>
        {content.title}
      </h2>
      <p className='text-black text-sm leading-7 text-center font-extrabold '>
        {content.description}
      </p>
    </div>
  );
}

// ---old version---
// export default function Roadmap() {
//   const [show, setShow] = useState(false);
//   const collapsableRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     collapsableRef.current && autoAnimate(collapsableRef.current);
//   }, [collapsableRef]);

//   return (
//     <div className='px-4'>
//       <div className='w-full mb-8 md:mb-12 '>
//         <p
//           className={`
//           bg-gradient-to-r from-orange-600 to-rose-500
//           text-transparent bg-clip-text font-extrabold text-5xl
//           text-center font-montserrat uppercase
//         `}
//         >
//           About utilities
//         </p>
//       </div>

//       <div
//         className={`
//           relative flex flex-col gap-8 md:gap-16 max-w-7xl overflow-hidden ${
//             show ? 'h-full' : 'max-h-[500px] lg:max-h-[750px]'
//           }`}
//         style={{
//           transition: 'all 0.5s ease-in-out'
//         }}
//       >
//         {content.map((item, index) => (
//           <div key={index}>
//             <div
//               ref={collapsableRef}
//               className={`
//               w-full flex justify-center items-start md:items-center gap-4 md:gap-6 lg:gap-8
//               flex-row ${
//                 index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'
//               }
//               `}
//             >
//               <div className='w-full hidden md:block max-w-xs md:max-w-sm lg:max-w-none' />
//               <Circle number={index + 1} />
//               <div className='w-full flex flex-col max-w-xs md:max-w-sm lg:max-w-none'>
//                 <p className='text-sm md:text-lg font-bold text-stone-800 mb-2 font-montserrat'>
//                   {item.title}
//                 </p>
//                 <p className='text-xs md:text-base font-montserrat'>
//                   {item.body}
//                 </p>
//               </div>
//             </div>
//           </div>
//         ))}

//         <div
//           className='w-2 h-full absolute top-14 left-7 md:left-1/2 transform md:-translate-x-1/2'
//           style={{
//             background:
//               'linear-gradient(180deg, #FFA963 0%, #FF8D8E 18.75%, #EC6F89 50%, #43657F 89.06%)'
//           }}
//         />
//         {show && (
//           <div className='w-full flex justify-center z-10'>
//             <p
//               className='w-fit px-8 py-4 rounded-lg text-white font-bold text-base cursor-pointer select-none bg-cyan-800'
//               onClick={() => {
//                 setShow(false);
//               }}
//             >
//               Hide
//             </p>
//           </div>
//         )}
//         {!show && (
//           <div className='absolute left-0 bottom-0 right-0 bg-gradient-to-t from-white z-10 h-full flex flex-col justify-end items-center gap-4 pb-6'>
//             <div
//               className='w-6 h-6 rounded-full'
//               style={{
//                 background: 'linear-gradient(186deg, #FFA766 0%, #E96E95 100%)'
//               }}
//             />
//             <div
//               className='w-10 h-10 rounded-full'
//               style={{
//                 background:
//                   'linear-gradient(194deg, #FFA36D 0%, #E86E97 52.41%, #B8688B 100%)'
//               }}
//             />
//             <div
//               className='w-14 h-14 rounded-full'
//               style={{
//                 background: 'linear-gradient(187deg, #E86E97 0%, #395469 100%)'
//               }}
//             />
//             <div>
//               <p
//                 className='w-fit px-8 py-4 rounded-lg text-white font-bold text-base cursor-pointer select-none'
//                 style={{
//                   background:
//                     'linear-gradient(360deg, #43657F 0%, #82607D 100%)'
//                 }}
//                 onClick={() => {
//                   setShow(true);
//                 }}
//               >
//                 See more
//               </p>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }
