import Image from 'next/image';
import React from 'react';

const line1 = [
  {
    img: '/partners/multiversx.svg',
    link: 'https://multiversx.com/'
  },
  {
    img: '/partners/s4f.svg',
    link: 'http://sense4fit.com/'
  }
];

const line2 = [
  {
    img: '/partners/bhero.svg',
    link: 'https://bhero.com/'
  },
  {
    img: '/partners/moon.svg',
    link: 'https://moonsociety.rocks/'
  },

  {
    img: '/partners/cowcow.svg',
    link: 'https://www.cowcow.io/'
  },
  {
    img: '/partners/society.png'
  },
  {
    img: '/partners/skynet.png',
    round: true
  },
  {
    img: '/partners/ixfi.svg',
    link: 'https://www.ixfi.com/'
  },
  {
    img: '/partners/webit-labs.svg',
    link: 'https://webitfactory.io/'
  },
  {
    img: '/partners/paradigm.png',
    round: true
  },
  {
    img: '/partners/notorious.png',
    round: true
  }
];

export default function Partners() {
  return (
    <div className='flex self-center w-full items-center flex-col mb-20 px-4 md:px-6'>
      <div className='flex flex-col-reverse md:flex-row items-center'>
        <div className='w-[250px] md:mr-10'>
          <Image
            src={'/home-page/HungryWhale.png'}
            alt='mission'
            width={339}
            height={213}
            layout='responsive'
            className='rounded-lg'
          />
        </div>
        <h2 className='text-secondary font-underwater text-center lg:text-start mt-6 mb-8 self-center capitalize text-5xl md:text-[60px] titleStrokeMobile md:titleStroke'>
          Our Partners
        </h2>
      </div>
      <div className='w-full border-black border-4 rounded-xl px-8 py-4'>
        <div className='flex items-center md:justify-evenly mt-4 mb-6 md:mb-2 flex-col md:flex-row flex-wrap gap-3 pb-2 md:pb-0 '>
          {line1.map((item: any, index: number) => (
            <a
              key={index}
              target='_blank'
              href={item.link ? item.link : ''}
              rel='noreferrer'
              className='relative flex justify-center md:pt-0 w-[140px] h-[62px]'
            >
              <Image
                layout='fill'
                objectFit='contain'
                style={{ position: 'relative' }}
                loading='lazy'
                className='relative cursor-pointer'
                alt={'partner'}
                src={item.img}
              />
            </a>
          ))}
        </div>
        <div className='flex items-center md:justify-evenly md:flex-row pt-0 md:pt-8 flex-wrap gap-4 justify-center'>
          {line2.map((item: any, index: number) => (
            <a
              key={index}
              target='_blank'
              href={item.link ? item.link : ''}
              rel='noreferrer'
              className={
                item.round
                  ? 'relative flex justify-center pt-8 mb-8 md:pt-0 w-[35px] h-[35px]'
                  : item.img === '/partners/society.png'
                  ? 'relative flex justify-center pt-8 mb-8 md:pt-0 w-[160px] h-[45px]'
                  : 'relative flex justify-center pt-8 mb-8 md:pt-0 w-[110px] h-[35px]'
              }
            >
              <Image
                layout='fill'
                objectFit='contain'
                style={{ position: 'relative' }}
                loading='lazy'
                className='partner1 relative cursor-pointer pt-20'
                alt={'partner'}
                src={item.img}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
