import WhitelistModal from './WhitelistModal';
import Image from 'next/image';
import { useState } from 'react';

export default function Navbar() {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      <div className='h-28 text-center fixed hidden lg:flex z-[1000] backdrop-blur-sm justify-between items-center p-8 font-underwater w-full bg-[#AAE2FF] bg-opacity-70  '>
        <div className='w-fit-content flex gap-2 '>
          <div
            className=' w-7 h-7 p-[0.5] rounded-md items-center justify-center bg-[#FF8200]
         '
          >
            <a
              target='_blank'
              href='https://twitter.com/thedreamywhales'
              rel='noreferrer'
            >
              <Image
                width={30}
                height={30}
                alt='neversea-discord'
                src='/home-page/universe-section/discord.svg'
              />
            </a>
          </div>
          <div
            className=' w-7 h-7 p-1 rounded-md items-center justify-center bg-[#FF8200]
         '
          >
            <a
              target='_blank'
              href='https://twitter.com/thedreamywhales'
              rel='noreferrer'
            >
              <Image
                alt='neversea-twitter'
                width={30}
                height={30}
                src='/home-page/universe-section/twitter.svg'
              />
            </a>
          </div>
        </div>
        <div className='tracking-wider flex lg:ml-28 xl:ml-[160px]   text-[18px] sm:text-2xl  gap-5 lg:gap-14 z-[10]  text-black  justify-center items-center '>
          <a href='#mission'>
            <h2 className='cursor-pointer hover:text-secondary w-[70px] uppercase'>
              mission
            </h2>
          </a>
          <a href='#ecosystem'>
            <h2 className='cursor-pointer hover:text-secondary w-[70px] uppercase'>
              vision
            </h2>
          </a>

          <div className='w-28  h-24 lg:w-36 lg:h-24  relative'>
            <Image
              src={'/home-page/dreamywhales-logo.png'}
              alt={'neversea-logo'}
              layout='fill'
              className='invert'
            />
          </div>
          <a href='#universe'>
            <h2 className='cursor-pointer hover:text-secondary w-[70px] uppercase'>
              lore
            </h2>
          </a>
          <a href='#team'>
            <h2 className='cursor-pointer hover:text-secondary w-[70px] uppercase'>
              team
            </h2>
          </a>
        </div>
        <div className='flex ml-2 min-w-[170px]  items-center'>
          <button
            className='border-2 border-black font-underwater text-black text-xl rounded-xl pt-1 px-1.5   w-fit-content  tracking-wider  uppercase bg-[#FF8200] '
            onClick={() => setShowModal(true)}
          >
            <p>Join the whitelist</p>
          </button>
        </div>
      </div>

      <WhitelistModal show={showModal} setShowModal={setShowModal} />
    </>
  );
}
