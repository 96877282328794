import React from 'react';

export default function CustomModal({
  show,
  setShowModal,
  children
}: {
  show: boolean;
  setShowModal: (show: boolean) => void;
  children: React.ReactNode;
}) {
  return (
    <div>
      {show && (
        <div
          className='relative z-20'
          aria-labelledby='modal-title'
          role='dialog'
          aria-modal='true'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full justify-center p-4 text-center items-center sm:p-0'>
              <div className='modal-background relative transform overflow-hidden rounded-xl text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl mx-4 px-6 py-4'>
                <div className='flex justify-end w-100'>
                  <button
                    className='rounded-full bg-[#FFFFFF] h-[25px] w-[25px] text-primary text-xs d-flex align-center justify-center'
                    onClick={() => setShowModal(false)}
                  >
                    ✖
                  </button>
                </div>
                <div className='mt-10 mb-10 mx-3 font-bold'>{children}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
