import React from 'react';

export function SmallButton({
  children,
  selected,
  className,
  ...props
}: {
  children: React.ReactNode;
  selected: boolean;
  onClick?: () => void;
  className?: string;
}) {
  return (
    <button
      className={
        'rounded-full pt-1 w-[130px] text-primary text-md font-bold uppercase font-underwater smallDescriptionStroke tracking-widest ' +
        (selected ? 'bg-[#FFFFFF] ' : 'bg-[#dde2eb] ') +
        className
      }
      {...props}
    >
      {children}
    </button>
  );
}
