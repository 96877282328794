import React from 'react';

export default function Ecosystem() {
  return (
    <div className='mt-8 p-4 md:p-6'>
      <div className='scroll-mt-[300px]' id='ecosystem'></div>
      <h2 className='text-secondary titleStrokeMobile md:titleStroke font-underwater mt-6 mb-8 text-center capitalize text-5xl md:text-[60px]'>
        Ecosystem explained
      </h2>
      <div className='w-full flex mb-16 gap-12 flex-col md:flex-row items-center'>
        <div className='w-full'>
          <p className='text-black text-center font-bold text-sm lg:text-lg'>
            Dreamy Whales is a 6000 NFT collection created under the Neversea
            festival vibes. It will become the first successful Web3 community
            brand gathered around the party industry. Our community, the Boop
            Boop Tribe participates, owns and shares common values in a
            flourishing and sustainable ecosystem. Having the biggest beach
            festival in Europe sustaining the community and the project in its
            hole, Neversea becomes the first entity to create a real, usable and
            sustainable NFT generated party brand. We’ve listened to our
            community and created an universe of possibilities meant to empower
            anyone that joins the Boop Boop Tribe and is committed to the
            ecosystem.
          </p>
        </div>
        {/* <div className='w-full md:w-[50%]'>
          <Image
            src={'/home-page/ecosystem.jpeg'}
            alt='mission'
            width={300}
            height={200}
            layout='responsive'
            className='rounded-lg'
          />
        </div> */}
      </div>
    </div>
  );
}
