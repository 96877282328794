/* eslint-disable @next/next/no-img-element */
// import styles from '../styles.module.css';

export default function FeatureRow({ title, subtitle, icon, align }: any) {
  return (
    <div className={'w-full flex flex-col text-center   gap-1 z-3'}>
      {/* <img
        className={`self-center  z-4 ${styles.badge}`}
        src={icon}
        alt={icon}
      /> */}
      <h2
        className={
          'text-2xl font-underwater  descriptionStroke font-normal text-secondary text-[33px] mb-1'
        }
      >
        {title}
      </h2>
      <p className={' font-bold text-sm text-black  '}>{subtitle}</p>
    </div>
  );
}
