import { useAutoAnimate } from '@formkit/auto-animate/react';
import Image from 'next/image';
import React, { useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function Parties() {
  const [selected, setSelected] = useState<string>('Wild Whales Parties');
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);
  const [textContainer] = useAutoAnimate();

  const descriptions: any = {
    'Wild Whales Parties':
      ' Wild Whales parties can happen anywhere, at any given time, whenever the community decides. There are no dates, locations or parameters set for the next party. Once the treasury will hit the milestone, the community will vote on the location, the time and the lineup for the next party.',
    'Custom made':
      'Everything is custom made with the Dreamy Whales theme, from DJ deck to ambient set - up, for a truly immersive experience. The sound is transmitted only by  Function One speakers.',
    '20% airdrop':
      '20% will be airdropped to the Wild Whales community after each successfully completed party.',
    'Limited party spots':
      'Each Wild Whale party edition is limited to 500 spots. Dreamy Whales NFT holders are the only ones that will give access to the party and only persons invited by the holders will be granted access.',
    'NFT holders decide':
      'NFT Holders will decide the price for every Wild Whales party ticket, according to location and line up and can only refer a maximum of two other persons to join the party. NFT Holders will have FREE lifetime access, while any referred person will pay the ticket in full.'
  };

  return (
    <div className='flex w-full self-center xl:w-full items-center flex-col mb-20 px-4 md:px-6'>
      <h2 className='text-secondary font-underwater text-center lg:text-start mt-6 mb-8 self-center  uppercase text-5xl md:text-[60px] titleStrokeMobile md:titleStroke'>
        What makes Wild Whales Party Label Unique?
      </h2>
      <p className='text-black text-center font-bold text-sm lg:text-lg mb-14'>
        Wild Whales parties can happen anywhere, at any given time, whenever the
        community decides. There are no dates, locations or parameters set for
        the next party. Once the treasury will hit the milestone, the community
        will vote on the location, the time and the lineup for the next party.
      </p>
      <div className='md:hidden w-full  border-black border-4 rounded-xl p-1  select-none'>
        <div className='w-full flex gap-1'>
          <p
            onClick={() => {
              if (index > 0) {
                swiperRef.slidePrev();
                setSelected(Object.keys(descriptions)[index - 1]);
                setIndex(index - 1);
              }
            }}
            className={`
                smallDescriptionStroke md:descriptionStroke text-secondary font-underwater cursor-pointer text-center
                p-5 border-black rounded-lg border-b-4 text-2xl ${
                  index === 0 ? 'opacity-30' : 'opacity-100'
                }
              `}
          >
            {'<'}
          </p>
          <Swiper
            onSwiper={(swiper) => {
              setSwiperRef(swiper);
            }}
          >
            {Object.keys(descriptions).map((keyName) => (
              <SwiperSlide key={keyName}>
                <p
                  className={`
                smallDescriptionStroke md:descriptionStroke text-secondary font-underwater cursor-pointer text-center
                p-5 h-full border-black rounded-lg border-b-4 flex justify-center items-center 
              `}
                >
                  {keyName}
                </p>
              </SwiperSlide>
            ))}
          </Swiper>
          <p
            onClick={() => {
              if (index < Object.keys(descriptions).length - 1) {
                swiperRef.slideNext();
                setSelected(Object.keys(descriptions)[index + 1]);
                setIndex(index + 1);
              }
            }}
            className={`
                smallDescriptionStroke md:descriptionStroke text-secondary font-underwater cursor-pointer text-center
                p-5 border-black rounded-lg border-b-4 text-2xl ${
                  index === Object.keys(descriptions).length - 1
                    ? 'opacity-30'
                    : 'opacity-100'
                }
              `}
          >
            {'>'}
          </p>
        </div>
        <div
          className='flex flex-col w-full py-8 px-3  justify-between'
          ref={textContainer}
        >
          <p className='text-black mt-4 w-full font-bold lg:mb-12 text-sm min-h-[180px]'>
            {descriptions[selected]}
          </p>
          <div className='relative w-[70vw] h-[40vw] flex self-center  md:mt-0'>
            <Image
              src={'/home-page/submarine.png'}
              alt='submarine'
              layout='fill'
              objectFit='contain'
            />
          </div>
        </div>
      </div>
      <div className='hidden w-full lg:w-full bg-cover bg-no-repeat md:flex mt-10 border-black border-4 rounded-xl mx-4 md:mx-6'>
        <div className='w-[70%] flex flex-col justify-between'>
          <ul className='list-none'>
            {Object.keys(descriptions).map((keyName, i) => (
              <li
                key={i}
                onClick={() => {
                  setSelected(keyName);
                }}
                className={`${
                  i === Object.keys(descriptions).length - 1
                    ? 'border-b-0 '
                    : 'border-b-4 '
                } 
             ${
               selected === keyName
                 ? 'smallDescriptionStroke md:descriptionStroke text-secondary font-underwater cursor-pointer pr-5 text-xl md:text-4xl transition-all relative py-8 w-[70%] border-black rounded-lg'
                 : 'text-black font-underwater cursor-pointer pr-5 text-xl md:text-4xl transition-all relative py-8 w-[70%] border-r-4 border-black rounded-lg'
             }`}
              >
                <div className='flex items-center'>
                  <p className='absolute inline-flex items-center justify-center w-4 h-4 bg-black rounded-full left-0 ml-2 mr-2'></p>
                  <p className='ml-10'>{keyName}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className='flex flex-col w-full py-8 pr-5 justify-between'>
          <p className='text-black mt-4 w-full md:pr-5 font-bold md:text-xl text-sm lg:text-lg'>
            {descriptions[selected]}
          </p>
          <div className='relative md:mt-0 w-[100%] h-[60%] flex self-center'>
            <Image
              src={'/home-page/submarine.png'}
              alt='submarine'
              layout='fill'
              objectFit='contain'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
