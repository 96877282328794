import WhitelistModal from '@components/shared/Navbar/WhitelistModal';
import Image from 'next/image';
import React, { useState } from 'react';

export default function Header() {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <div className='flex relative flex-col items-center mb-0 md:mb-20'>
        {/* <h1 className='text-secondary descriptionStroke font-underwater text-3xl md:text-6xl font-bold w-[90%] md:w-9/10  tracking-wide text-center leading-tight'>
          We are the day dreamers. We are the trendsetters. We vibe as you vibe.
        </h1>
        <h2 className='text-white text-sm md:text-lg mt-7 text-center w-9/12'>
          Dreamy Whales is a community of day dreamers united under the spirit
          of Neversea in a unique mega tribe built on the principles of
          acceptance, integration and evolution.
        </h2> */}
        <div className='absolute md:bottom-[17%] sm:bottom-[32%] bottom-[120px] h-[8%] sm:h-[9%] w-[45%]'>
          <Image
            alt='neversea-title'
            src='/home-page/Daydreamers-speed15.png'
            layout={'fill'}
          />
        </div>
        <div className='w-full mt:0 md:mt-20 relative  z-[-2]'>
          <Image
            src={'/home-page/main-scene.png'}
            alt='neversea-logo'
            width={3148}
            height={1650}
            layout='responsive'
          />
        </div>
        <div className='flex md:hidden items-center w-fit-content w-full justify-center p-4'>
          <button
            className='border-2 border-black font-underwater text-black text-md md:text-2xl rounded-xl pt-2 px-2 w-full tracking-wider uppercase bg-[#FF8200] mt-2'
            onClick={() => setShowModal(true)}
          >
            Join the whitelist
          </button>
        </div>

        <WhitelistModal show={showModal} setShowModal={setShowModal} />
      </div>
    </>
  );
}
