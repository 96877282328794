// import Image from 'next/image';
import React from 'react';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const nfts = [
  {
    image: '/nfts/rare1.png'
  },
  {
    image: '/nfts/gigglefin.jpeg'
  },
  {
    image: '/nfts/rare3.png'
  },
  {
    image: '/nfts/offshore-jack.jpeg'
  },
  {
    image: '/nfts/rare6.png'
  },
  {
    image: '/nfts/whalerond.jpeg'
  },
  {
    image: '/nfts/rare8.png'
  },
  {
    image: '/nfts/whimsy.jpeg'
  }
];

export default function Nfts() {
  return (
    <div className='mb-20 md:32 px-4 md:px-6'>
      <div className='flex justify-center flex-col mb-20'>
        <h2 className='text-5xl md:text-[60px] titleStrokeMobile md:titleStroke text-secondary  mb-6 font-underwater text-center uppercase'>
          The Collection
        </h2>
        <p className='text-sm lg:text-lg text-black font-bold text-center self-center max-w-[1200px] w-85 mt-2'>
          Dreamy Whales is a community first NFT project developed on MultiversX
          blockchain by a dedicated team of diverse cultural origins, sex and
          religion. The collection is an expression of tolerance, acceptance and
          good vibes. It represents the very core of the Neversea festival and
          it exists only through its community power.
        </p>
      </div>
      <div className='flex flex-col'>
        <Swiper
          loop={true}
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            500: { slidesPerView: 2 },
            800: { slidesPerView: 3 },
            1150: { slidesPerView: 4 },
            1325: { slidesPerView: 5 }
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }}
          pagination={{
            el: '.swiper-custom-pagination2',
            clickable: true
          }}
          modules={[Autoplay, Pagination]}
          className='mt-70 w-full  mb-50 flex  justify-center'
        >
          {nfts.map((item: any, index: number) => (
            <SwiperSlide
              key={index}
              className='w-full h-[100%] flex rounded-xl cursor-pointer justify-center'
            >
              <img
                loading='lazy'
                alt='whales carousel'
                src={item.image}
                // layout='fill'
                // objectFit='cover'
                className='rounded-xl  border-black border-4 w-full'
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className='swiper-custom-pagination2 hidden justify-center mt-6 content-center mb-130' />
      </div>
    </div>
  );
}
