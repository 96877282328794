import FeatureRow from './FeatureRow';
import styles from './styles.module.css';
import Image from 'next/image';
import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';

export default function Universe() {
  return (
    <div
      id='universe'
      className='flex scroll-mt-[100px] flex-col justify-center items-center mb-10 md:mb-32'
    >
      <div className='flex justify-center flex-col mb-16'>
        <h2 className='text-secondary font-underwater text-center lg:text-start mt-6 mb-8 self-center uppercase text-5xl md:text-[60px] titleStrokeMobile md:titleStroke'>
          The Dreamy Whales Universe
        </h2>
        <p className='text-black text-center self-center max-w-[1000px] w-[95%] text-sm lg:text-lg font-bold   mt-2'>
          Made possible only due to community commitment, the Dreamy Whales
          Universe has become legendary and will remain so.The Universe is
          formed of the following pillars:
        </p>
      </div>

      <div
        className={`flex w-[90%] relative flex-col md:flex-row  ${styles.container} p-8 gap-8 sm:p-8 lg:p-16 rounded-[15px]`}
      >
        <div className='w-full flex flex-col gap-10 space-between '>
          <div>
            <FeatureRow
              title={'Neversea Festival Foundation Romania & Dubai'}
              subtitle={
                'Offering unique utilities for every edition. Check more on the official website: '
              }
              icon={'/home-page/universe-section/party.svg'}
              align={'end'}
            />
            <a href="https://neversea.com" target='_blank' className='font-bold text-sm text-black flex justify-center w-full hover:text-gray-700' rel="noreferrer"> https://neversea.com </a>
          </div>
          <div>
            <FeatureRow
              title={'Dreamy Whales NFT Collection'}
              subtitle={'Check our Discord and Twitter'}
              icon={'/home-page/universe-section/nft2.svg'}
              align={'end'}
            />
            <div className='w-full flex  reltaive justify-center gap-5 mt-2'>
              <a
                target='_blank'
                href='https://twitter.com/thedreamywhales'
                rel='noreferrer'
                className='w-[24px] h-5 relative'
              >
                <Image
                  alt='neversea-twitter'
                  layout='fill'
                  src='/home-page/universe-section/twitter.svg'
                />
              </a>
              <a
                className='w-[24px] h-6 relative'
                target='_blank'
                href='https://discord.gg/dreamywhale'
                rel='noreferrer'
              >
                <Image
                  alt='neversea-discord'
                  layout='fill'
                  src='/home-page/universe-section/discord.svg'
                />
              </a>
            </div>
          </div>
        </div>
        <img
          src={'/home-page/universe-section/balena-inapa2.png'}
          alt={'neversea-whale'}
          className='lg:w-[450px] w-[300px] md:w-[350px] hidden md:block absolute left-0 right-0 bottom-[-80px] ml-auto mr-auto z-4'
        />
        <div className='hidden md:flex md:w-[1200px] lg:w-[1400px]  bg-transparent flex-col relative'></div>
        <div className=' md:w-full flex justify-between flex-col gap-8 '>
          <FeatureRow
            title={'Whale Academy'}
            subtitle={
              'Web3 Talent Scouting : From Zero to H3ro is the portal that unites our community of dreamers with opinion leaders, artists, influencers, music producers, etc. Join the academy of the Boop Tribe'
            }
            icon={'/home-page/universe-section/academy.svg'}
            align={'start'}
          />

          <FeatureRow
            title={'Wild Whales'}
            subtitle={'The Only Community Owned Party Label'}
            icon={'/home-page/universe-section/party2.svg'}
            align={'start'}
          />
        </div>
        <img
          src={'/home-page/universe-section/balena-inapa2.png'}
          alt={'neversea-whale'}
          className='lg:w-[500px] w-[450px] md:w-[400px] md:hidden block relative  left-0 right-0 bottom-[-32px] ml-auto mr-auto z-4'
        />
      </div>
    </div>
  );
}

// -- OLD VERSION --
// return (
//   <>
//     <div className='flex gap-4 mb-7 flex-col md:flex-row items-center px-4'>
//       <h1 className='text-primary  text-center text-3xl md:text-5xl font-bold w-[90%] md:w-1/2 tracking-wide md:text-start leading-tight'>
//         The Dreamy Whales Universe
//       </h1>
//       <div className='w-[90%] md:w-1/2'>
//         <p className='text-primary md:text-start text-center mt-4 md:pr-5 font-thin text-sm lg:text-md'>
//           Made possible only due to community commitment, the Dreamy Whales
//           Universe has become legendary and will remain so.
//         </p>
//         <p className='text-primary md:text-start text-center mt-4 md:pr-5 font-thin text-sm lg:text-md'>
//           The Universe is formed of the following pillars:
//         </p>
//       </div>
//     </div>
//     <Collections />
//   </>
// );
