import CustomModal from '../Waitlist/CustomModal';
import { SmallButton } from '../Waitlist/SmallButton';
import { useState } from 'react';

export default function WhitelistModal({
  show,
  setShowModal
}: {
  show: boolean;
  setShowModal: (show: boolean) => void;
}) {
  const [handleType, setHandleType] = useState<string>('email');
  const [handle, setHandle] = useState<string>('');
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [walletAddressError, setWalletAddressError] = useState<boolean>(false);
  const [successful, setSuccessful] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const btns = ['email', 'twitter', 'telegram', 'discord'];

  const submit = () => {
    const isValidAddress = isValidElrondAddress(walletAddress);
    if (isValidAddress) {
      fetch('https://api.dreamywhales.io/api/v1/whitelist', {
        method: 'POST',
        body: JSON.stringify({
          handle,
          handleType,
          walletAddress
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Access-Control-Allow-Origin': '*'
        }
      })
        .then(() => {
          setSuccessful(true);
        })
        .catch((err) => {
          console.error(err);
          setError(true);
        });
    } else {
      setWalletAddressError(true);
    }
  };

  const setShowModalTmp = () => {
    setSuccessful(false);
    setError(false);
    setHandle('');
    setWalletAddress('');
    setShowModal(false);
  };

  return (
    <CustomModal show={show} setShowModal={setShowModalTmp}>
      {successful
        ? (
          <div className='text-white text-center text-xl mb-8'>
          Registration successful. <br />
          Thank you for joining our whitelist.
          </div>
          )
        : error
          ? (
            <div className='text-white text-center text-xl mb-8'>
          There was an error.
              <br />
          Please try again later.
            </div>
            )
          : (
            <div className="flex flex-col justify-center">
              <div className="flex flex-col items-center">
                <div className="text-white font-bold text-center md:text-left text-md md:text-2xl md:w-10/12 mt-8 md:mt-4 font-underwater smallDescriptionStroke">
            JOIN THE WAITLIST & YOU HAVE A CHANCE AT BEING INCLUDED IN OUR
            WHITELIST
                </div>
              </div>
              <div className='flex flex-col md:flex-row justify-evenly'>
                <div className='flex flex-col items-center'>
                  <div className='text-center text-[#FFFFFF] text-sm font-medium mt-3'>
                Register with
                  </div>
                  <div className='flex flex-col gap-4 mt-4 justify-center'>
                    {btns.map((btn) => {
                      return (
                        <SmallButton
                          key={btn}
                          onClick={() => setHandleType(btn)}
                          selected={handleType === btn}
                        >
                          {btn}
                        </SmallButton>
                      );
                    })}
                  </div>
                </div>
                <div className='flex flex-col items-center md:items-start mt-8 md:mt-0'>
                  <div className='text-[#FFFFFF] items-center text-sm font-medium mt-3 mb-4'>
                Enter MultiversX / Elrond Wallet Public Address:
                  </div>

                  <div className='flex items-center flex-wrap w-full justify-center md:justify-start'>
                    <input
                      type='text'
                      id='small-input'
                      className='placeholder-white block w-full md:w-[300px] p-2 text-white border-2 border-white rounded-full bg-transparent sm:text-xs focus:ring-blue-500 focus-visible:outline-none'
                      value={handle}
                      required={true}
                      name='firstName'
                      onChange={(e) => setHandle(e.target.value)}
                      placeholder={`${handleType} handle`}
                    ></input>
                  </div>

                  <div className='flex flex-col flex-wrap mt-3 w-full'>
                    <input
                      type='text'
                      id='small-input'
                      required={true}
                      className='placeholder-white block w-full md:w-[300px] p-2 text-white border-2 border-white rounded-full bg-transparent sm:text-xs focus:ring-blue-500 focus-visible:outline-none'
                      value={walletAddress}
                      name='walletAddress'
                      onChange={(e) => setWalletAddress(e.target.value)}
                      placeholder='wallet address'
                    ></input>
                    {walletAddressError && (
                      <p className='text-[#C6290F] text-xs ml-2 mt-0.5'>
                    Invalid wallet address
                      </p>
                    )}
                  </div>

                  <div className='text-[#FFFFFF] text-xs font-light mt-4 w-10/12'>
                *You are eligible for the whitelist ONLY if you have a
                MultiversX Wallet
                  </div>

                  <SmallButton
                    onClick={submit}
                    selected={false}
                    className=' w-[300px] border-3 mt-3'
                  >
                SUBMIT
                  </SmallButton>
                </div>
              </div>
            </div>
            )}
    </CustomModal>
  );
}

function isValidElrondAddress(address: string): boolean {
  if (typeof address !== 'string' || address.length < 40) {
    return false;
  }

  if (!address.startsWith('erd1')) {
    return false;
  }

  if (!/^[a-z0-9.-]+$/.test(address)) {
    return false;
  }

  return true;
}
