export const content = [
  {
      title: '1200 One-Day Festival Access - One-Time Use',
      description:
        'Whoa! If you are lucky enough to get the Dreamy Whale that has a full-day pass at the Neversea festival, you are in for a treat. This benefit can be claimed for this edition of the festival or the next.'
    },
    {
      title: 'Meet the artist - 10 lucky Whales - One-Time Use',
      description:
        'We will make a dream come true for 10 Dreamy Whales! Fingers crossed as it is randomly generated and claimable in this edition or the next of the Neversea festival.'
    },
    {
      title: 'Early Bird Ticket Price - Lifetime',
      description:
        'Every Dreamy Whale will have the best price guaranteed on Neversea tickets for life. Period.'
    },
    {
      title:
        'Lifetime Access to Neversea Festival - Limited to 5 Dreamy Whales*',
      description:
        "The rarest of our Whales will have lifetime access to the Neversea festival. Yes, only 5 NFTs that will be randomly generated. That's correct!"
    },
    {
      title:
        'Wild Whales Party Access - Lifetime',
description: "Is the festival over? Maybe, but the party continues with the Wild Whales Party concept, and all Dreamy Whales will have free access to every party edition. Let's rock!"
    },
    {
      title:
        'VIP Access to the "Ahoy" Crypto Hub during the Neversea Festival ',
      description:
        "We'll always have a space of our own at each Neversea edition! This time we have a 400m2 playground, and as it is open for all, Dreamy Whales will have VIP access to the lounge area."
    },
    {
      title: 'Whale Academy - Web3 Talent Scouting App',
      description:
        '"From Zero to H3ro" is the portal that unites our community of dreamers with opinion leaders, artists, influencers, music producers, etc.'
    },
    {
      title: 'Special Airdropps Directly to All Holders - Lifetime',
      description:
        'The Dreamy Whales Universe is designed to thrive, from the Wild Whales parties to the "Ahoy" Crypto hub and many more in development. As this is a community-first project, everything will be shared with the community.'
    },
    {
      title: 'Beach Gym Full Access - Lifetime',
      description:
        "The opening of the beach gym will take place after the Neversea festival, and all Dreamy Whales will have access to it. Based on the success of the concept, we'll vote for the opening in many other locations in Europe."
    },
     {
      title: '10 Backstage Passes',
      description:
        '10 Backstage Passes will be give to 10 lucky nft holders to party like never before at Neversea festival!'
    },
     {
      title: 'Idle Game Launch',
      description:
        'In Progress..'
    },
     {
      title: 'Unique Stacking Mechanics',
      description:
        'In Progress..'
    }
];
