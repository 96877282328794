// import styles from './styles.module.css';
import Image from 'next/image';
import React from 'react';

export default function Mission() {
  return (
    <div id='mission' className='p-4 scroll-mt-[300px] md:p-6'>
      <div className='w-full flex gap-12 flex-col md:flex-row items-center'>
        <div className='w-full md:w-[45%]'>
          <h2
            className={
              'text-secondary titleStrokeMobile md:titleStroke font-underwater mt-6 mb-8 md:text-left text-center capitalize text-5xl md:text-[60px]'
            }
          >
            Mission
          </h2>
          <p className='text-black text-center md:text-start font-bold text-sm lg:text-lg'>
            Our community is built to unite, it’s meant to thrive, it serves to
            vibe. Our mission is to reachout to every Whale outhere, to dream
            the impossible and build the unspeakable. Our culture is built on
            helping each member of the Dreamy Whales community to evolve,
            overcome and ascend. A vibrant whale community will soon reach the
            shores of Neversea.
          </p>
        </div>
        <div className='w-full md:w-[55%] h-50'>
          <Image
            src={'/home-page/mission.png'}
            alt='mission'
            width={300}
            height={150}
            layout='responsive'
            className='rounded-lg'
          />
        </div>
      </div>
    </div>
  );
}
