import Link from 'next/link';
import { Url } from 'url';

export default function TeamCard({
  name,
  role,
  image,
  description,
  link
}: {
  name: string;
  role: string;
  image: string;
  description: string;
  link?: Url;
}) {
  return (
    <div className='w-full'>
      <div className='border-4 border-black rounded-2xl relative mb-5 w-full'>
        {link !== undefined
? (
          <Link href={link} target='_blank'>
            <img
              src={image}
              alt={'neversea-house'}
              className='rounded-3xl cursor-pointer'
            />
          </Link>
        )
: (
          <img
            src={image}
            alt={'neversea-house'}
            className='rounded-xl  '
          />
        )}
      </div>
      <div className='h-[120] flex flex-col items-center'>
        <p className='text-secondary descriptionStroke font-underwater break-words max-w-[300px] text-center capitalize text-4xl mb-3'>
          {name}
        </p>
        <p className='text-black break-words  max-w-[300px] text-center capitalize text-md font-bold'>
          {role}
        </p>
      </div>
    </div>
  );
}
