import React from 'react';

export default function Footer() {
  return (
    <div className='w-1/2 self-center grid grid-cols-1 sm:grid-cols-4 items-center text-center gap-5 text-sm text-black p-2 mb-6'>
      <a className='hover:text-[#1d52ae]' target='_blank' href='/privacy'>
        Privacy Policy
      </a>
      <a className='hover:text-[#1d52ae]' target='_blank' href='/cookiepolicy'>
        Cookie Policy
      </a>
      <a className='hover:text-[#1d52ae]' target='_blank' href='/termsofsale'>
        Terms of Sale
      </a>
      <a className='hover:text-[#1d52ae] '>©2023, Dreamy Whales</a>
    </div>
  );
}
