import BurgerMenu from './BurgerMenu';
import Footer from '@components/shared/Footer/Footer';
import Navbar from '@components/shared/Navbar/Navbar';

const Layout = ({ children }: any) => {
  return (
    <div className='flex flex-col min-h-screen'>
      <Navbar />
      <BurgerMenu />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
