import TeamCard from './TeamCard';
import React from 'react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

const team = [
  {
    fullname: 'Leonida Duduleanu',
    image: '/team/leonida.jpeg',
    role: 'Team Lead - “Avery”',
    description:
      'Avery is the Web3 architect that never settles for less. It’s degenerative side is completely developed to create mesmerising stories and build unthinkable worlds. He has more than 15 years in digital marketing, part of more than 30 Web3 projects. He strongly believes in aliens.'
  },
  {
    fullname: 'Victor Alexandru',
    image: '/team/victor.png',
    role: 'Lead Op. - “Beluga”',
    description:
      'Beluga is covered in yakuza tattoos and simply gets shit done. He sees the unseeable and is connected to everything non fungible. He takes Vitamin D as he believes it amplifies the degen side  of the hippocampus. Founder of a Web3 agency and involved in launching 40+ blockchain games.'
  },
  {
    fullname: 'Chris Orasanu',
    image: '/team/chris.jpeg',
    role: 'Fins Orchestrator - “Humpback SDK”',
    description:
      'Humpback mixes the whale songs and orchestrates sea currents to go in the right direction. He is coordinating partnerships, collabs and outreaches new territories. His background in cybersec products, fintech, VC and Privatey Equity was deemed worthless once he has seen Little Mermaid one to many times and developed a fish fetish.'
  },
  {
    fullname: 'Melika Monjazi',
    image: '/team/melika.jpeg',
    role: 'Lead illustrator -  “QueenMeli”',
    description:
      'Iranian-Ukrainian computer engineer-turned-Artist. 10 years ago she decided to make a career from her passion, art! Today she introduces herself as a Digital Artist and developer. But no one knows who she’ll be tomorrow, as she says she’s a work in progress.'
  },
  {
    fullname: 'Bogdan Doros',
    image: '/team/bogdan.png',
    role: 'Co General Manager - Neversea',
    description:
      '4 years Head of B2C at Untold and General Manager at Neversea. One of the pillars that took the Neversea festival to the ultimate level adn made it the biggest beach festival on Europe.'
  },
  {
    fullname: 'Bogdan Radulescu',
    image: '/team/bogdan-radulescu.jpeg',
    role: 'Chief Business Officer UNTOLD Universe',
    description:
      'Establishing Strategic Partnerships · New Business Development · Business Relationship Management · International Business · Event Management · Strategic Leadership'
  },
  {
    fullname: 'Cerasela Usturoi',
    image: '/team/cera.jpeg',
    role: 'Lead Creative - “Cera”',
    description:
      'Cera is dripping fluorescent colours in her eyes, lives and breathes new age art of all worlds. She is a creative strategus, a bridge between dreams and colours. She believes NFT’s have soul.'
  },
  {
    fullname: 'WebitLabs Team',
    image: '/team/webitfactory.png',
    // link: 'https://webitfactory.io/',
    role: 'Lead Technology',
    description:
      'They’ve scored 10 outta 10 on the international space station ethical hacking program and initiated the first race between satellites. WebitLabs has the most dedicated team on decoding and encoding our new world. It’s all in the code, bro.'
  },
  {
    fullname: 'Narcis Iliescu',
    image: '/team/narcis.png',
    role: 'Community lead - “Narq”',
    description:
      'Narq and his team of wackos are leading our community into the amazing journey ahead. He is all-in in making the Boop-boop tribe the colossus of Web3.  His engaging energy is unparalleled.'
  },
  {
    fullname: 'Andreea Fiterau',
    image: '/team/andreea.jpeg',
    role: 'Tech Growth Lead - “Andre”',
    description:
      'Andre is an organic growth witch that masters the art of bots, automation and integration. Her digital totems are unifying communities. It has been said that she is a distant relative of Gandalf.'
  },
  {
    fullname: 'Raion',
    image: '/team/raion.jpeg',
    role: 'Lead Artist',
    description:
      'Raion is making any digital canvas into pi3ces of art that turn in legendary collections. He has been involved in tons of epic collections.'
  },
  {
    fullname: 'The Rite',
    image: '/team/therite.png',
    role: 'Senior Artist',
    description:
      'The Rite is the digital artmaster, the nomad artist, the pixel whisperer. In his hands, the Whales started talking.'
  },
  {
    fullname: 'Kom',
    image: '/team/kom.jpeg',
    role: 'Junior Artist',
    description:
      '.Kom is the exception from the norm, the one true free spirit with immense creative power. Kom know traits like no other.'
  }
];

export default function Team() {
  return (
    <>
      <div className='scroll-mt-[100px]' id='team'>
        <div className='mb-20 px-4 md:px-6 mt-16 md:mt-32'>
          <div className='flex justify-center flex-col mb-20'>
            <h2 className='text-5xl titleStrokeMobile md:titleStroke md:text-5xl font-underwater text-secondary text-center capitalize md:text-[65px]'>
              The Dreamy Whales
            </h2>
          </div>
          <div className='flex flex-col'>
            <Swiper
              loop={true}
              spaceBetween={20}
              slidesPerView={1}
              breakpoints={{
                500: { slidesPerView: 2 },
                800: { slidesPerView: 3 },
                1150: { slidesPerView: 4 },
                1325: { slidesPerView: 5 }
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false
              }}
              pagination={{
                el: '.swiper-custom-pagination3',
                clickable: true
              }}
              modules={[Autoplay, Pagination]}
              className='mt-70 w-full  mb-50 flex  justify-center'
              >
              {team.map((item: any, index: any) => {
                return (
                  <SwiperSlide key={index} className='w-full  flex rounded-xl cursor-pointer justify-center'
                  >
                    <TeamCard
                      name={item.fullname}
                      image={item.image}
                      link={item.link}
                      role={item.role}
                      description={item.description}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className='swiper-custom-pagination3  hidden justify-center mt-6 content-center mb-130' />
          </div>
        </div>
      </div>
    </>
  );
}

// --- Old version ----
// const [swiperEnd, setSwiperEnd] = useState<boolean>(false);
// const [swiperStart, setSwiperStart] = useState<boolean>(true);

// const prevHandler = () => {
//   swiperRef.slidePrev();
// };

// const nextHandler = () => {
//   swiperRef.slideNext();
// };

// <>
//   <div className='scroll-mt-[100px]' id='team'></div>

//   <div className='w-full flex mt-20 overflow-hidden py-4'>
//     <div
//       style={{
//         width: '100%',
//         maxWidth: 'calc((100% - 1280px) / 2)',
//       }}
//     />
//     <div className='w-full relative overflow-hidden'>
//       <div className='flex relative sm:flex-row w-[90%] md:w-full ml-4 flex-col gap-3'>
//         <h2 className='text-primary  w-[300px] uppercase text-5xl font-bold'>
//           The Dreamy Team
//         </h2>
//         <div className='h-fit flex gap-3'>
//           <Image
//             alt={'previous-button'}
//             onClick={prevHandler}
//             width={76}
//             height={67}
//             className={
//               swiperStart
//                 ? 'cursor-pointer  opacity-30'
//                 : 'cursor-pointer z-10 hover:opacity-90'
//             }
//             src={'/home-page/button-prev.jpeg'}
//           />
//           <Image
//             alt={'next-button'}
//             onClick={nextHandler}
//             width={76}
//             height={67}
//             className={
//               swiperEnd
//                 ? 'cursor-pointer  opacity-30'
//                 : 'cursor-pointer z-10 hover:opacity-90'
//             }
//             src={'/home-page/button-next.jpeg'}
//           />
//         </div>
//       </div>
//       <Swiper
//         onReachBeginning={() => {
//           setSwiperStart(true);
//         }}
//         onReachEnd={() => {
//           setSwiperEnd(true);
//         }}
//         onSlideChange={() => {
//           swiperEnd === true && setSwiperEnd(false);
//           swiperStart === true && setSwiperStart(false);
//         }}
//         onSwiper={(swiper) => setSwiperRef(swiper)}
//         modules={[Navigation, Pagination, A11y]}
//         spaceBetween={30}
//         className={styles.swiper}
//         slidesPerView={'auto'}
//       >
//         {/* <SwiperNavButtons /> */}
//         {team.map((item: any, index: any) => {
//           return (
//             <SwiperSlide key={index} className={styles.slide}>
//               <TeamCard
//                 name={item.fullname}
//                 image={item.image}
//                 link={item.link}
//                 role={item.role}
//                 description={item.description}
//               />
//             </SwiperSlide>
//           );
//         })}
//       </Swiper>
//     </div>
//   </div>
// </>;
