// import '../styles/styles.css';
import Layout from '@components/layouts/home-layout';
// import Collections from '@components/pages/home-page/Collections';
import Ecosystem from '@components/pages/home-page/Ecosystem/Ecosystem';
import Header from '@components/pages/home-page/Header/Header';
import Mission from '@components/pages/home-page/Mission';
import Nfts from '@components/pages/home-page/Nfts';
import Parties from '@components/pages/home-page/Parties';
import Partners from '@components/pages/home-page/Partners';
// import Partners from '@components/pages/home-page/Partners';
import Team from '@components/pages/home-page/Team/Team';
import Universe from '@components/pages/home-page/Universe';
// import WildWhales from '@components/pages/home-page/WildWhales';
import Roadmap from '@components/pages/roadmap';
import React from 'react';

export default function Home() {
  return (
    <div className='w-full self-center justify-center relative max-w-screen-xl flex flex-col mt-28 '>
      <Header />
      <Mission />
      <Ecosystem />
      <Universe />
      {/* <WildWhales /> */}
      <Parties />
      <Nfts />
      <Roadmap />
      <Team />
      <Partners />
    </div>
  );
}

Home.getLayout = function getLayout(page: any) {
  return <Layout>{page}</Layout>;
};
